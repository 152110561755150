.App {
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* Ensures it covers at least the full height of the viewport */
}




.App-logo {
  height: 100vmin;
  /* Smaller than before for better responsiveness */
  pointer-events: none;
  max-height: 500px;
  /* Maximum height to ensure it doesn't get too large on big screens */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
    /* Slower spin for better UX */
  }
}

.App-header {
  background-color: #282c3400;
  min-height: 50vh;
  /* Reduced min-height for better responsiveness */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4vmin;
  /* Smaller base font size with responsiveness in mind */
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  color: rgb(0, 0, 0);
}

.headerText {
  font-size: 4vmin;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  color: red;
  /* Initial color, the animation will override this */
  animation: rainbow-text 10s infinite linear;
}

@keyframes rainbow-text {

  0%,
  100% {
    color: red;
  }

  8% {
    color: orange;
  }

  16% {
    color: yellow;
  }

  25% {
    color: green;
  }

  33% {
    color: blue;
  }

  41% {
    color: indigo;
  }

  50% {
    color: violet;
  }

  58% {
    color: indigo;
  }

  66% {
    color: blue;
  }

  75% {
    color: green;
  }

  83% {
    color: yellow;
  }

  91% {
    color: orange;
  }
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.icon-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  /* Removes underline from anchor tags */
  display: inline-block;
  /* Aligns properly with other inline elements */
}

.icon-button img {
  height: 50px;
  /* Set the size as needed */
  width: auto;
}


.App-link {
  color: #61dafb;
}

/* Responsive Image Grid */
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Always creates 4 columns */

  padding: 10px;
}

.grid-image {
  width: 100%;
  height: 100%;
  /* Fixed height */

}

.investors-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns in larger screens */
  grid-gap: 10px;
  padding: 10px;
}

.investor {
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  color: rgb(0, 0, 0);
  text-align: center;
  /* Centering text and images */
}

.investor-image {
  width: 25%;
  /* Responsive image sizing */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 50%;
  border: 1px solid black;
  /* Circular images */
}

.investor-name {
  margin-top: 50px;
  /* Space between image and name */
  color: #000000;
  /* Text color, adjust as needed */
  font-size: 1.2em;
  /* Larger text for names */
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns on larger screens */
  grid-gap: 20px;
  padding: 20px;

  /* Optional: Background color for the team section */
}

.team-member {
  text-align: center;
  /* Center align the content */
}

.team-image {
  width: 100%;
  /* Responsive image sizing */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 50%;
  /* Circular images for a friendly look */
}

.team-name {
  margin-top: 10px;
  /* Space between image and name */
  color: #000000;
  /* White text color */
  font-weight: bold;
  /* Bold font for emphasis */
}

.team-title {
  color: #292626;
  /* Lighter color for titles */
  font-size: 0.9em;
  /* Smaller font size for titles */
}

/* Responsive adjustments */


/* Media Queries for responsive adjustments */
@media (max-width: 768px) {
  .investors-grid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns in medium screens */
  }
}

@media (max-width: 480px) {
  .investors-grid {
    grid-template-columns: 1fr;
    /* 1 column in small screens */
  }
}


/* Media Queries */
@media (max-width: 1200px) {

  /* When the screen size is less than 1200px, reduce the number of columns to 3 */
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {

  /* When the screen size is less than 768px, reduce the number of columns to 2 */
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .App-logo {
    height: 30vmin;
  }

  .App-header,
  .headerText {
    font-size: 5vmin;
  }
}

@media (max-width: 480px) {

  /* When the screen size is less than 480px, stack the images in a single column */
  .image-grid {
    grid-template-columns: 1fr;
  }

  .App-logo {
    height: 40vmin;
  }

  .App-header,
  .headerText {
    font-size: 6vmin;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App {
    background-image: url('/public/4.webp');


    background-size: cover;

  }
}

@media (max-width: 480px) {
  .App {
    background-image: url('/public/4.webp');


    background-size: contain;

  }
}